require("fslightbox");

import swal from 'sweetalert2';
window.Swal = swal;

window._ = require("./helpers");
window.Validate = require("./validate");

window.Loading = require("./loading");

require("./datatable");
window.Mask = require("./mask");
require("./sortable");
window.SweetAlert = require("./sweetalert");

window.FormValidation = require("./form-validation");
window.Form = require("./form");
require("./form-email");

require("./includes");
