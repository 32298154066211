document.addEventListener("DOMContentLoaded", () => {
    [].forEach.call(document.querySelectorAll('[data-sortable="reorder"]'), el => {
        let path = el.dataset.path;

        Sortable.create(el, {
            onEnd: () => {
                let ordem = Sortable.get(el).toArray();

                fetch(`api/${path}/reorder`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(ordem)
                });
            }
        });
    });
});
