const prevents = e => {
    e.preventDefault();
    e.stopPropagation();
};




const addItem = (image, path) => {
    document.querySelector(".dropzone-list").insertAdjacentHTML("beforeend", `
        <li class="dropzone-item" data-id="${image.id}">
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group mb-0">
                        <input type="text" class="dropzone-legend form-control form-control-sm"
                            value="${image.legend}">
                    </div>
                </div>

                <div class="col-md">
                    <a class="zoom mr-3" href="./storage/${path}/${image.name}" data-fslightbox="gallery" data-type="image">
                        <u>Visualizar</u>
                    </a>

                    <a href="./storage/${path}/${image.name}" download="">
                        <u>Download</u>
                    </a>
                </div>

                <div class="col-md-auto">
                    <button class="dropzone-trash">
                        <i class="bi bi-x-lg"></i>
                    </button>
                </div>
            </div>
        </li>
    `);

    MagnificPopup.zoom();
};

const sendFiles = (files, path, relation) => {
    if (files.length > 0) {
        Array.from(files).forEach(file => {
            let data = new FormData();
            data.append("name", file);
            data.append(relation.attribute, relation.id);
            data.append("legend", file.name);

            fetch(`/api/${path}`, {
                method: "POST",
                headers: {
                    "Accept": "application/json"
                },
                body: data
            }).then(response => {
                if (!response.ok) {
                    if (response.status === 422) {
                        SweetAlert.error(file.name + " não tem um formato válido.");
                    }

                    return;
                }

                response.json().then(image => {
                    addItem(image, path);
                });
            });
        });
    }
};

const dropHandler = e => {
    prevents(e);
    let droppedFiles = e.dataTransfer.files;
    let path = e.target.dataset.path;
    let relation = {
        attribute: e.target.dataset.relationAttribute,
        id: e.target.dataset.relationId
    };
    sendFiles(droppedFiles, path, relation);
    e.target.classList.remove("is-dragover");
};




const trashHandler = function () {
    let item = this.closest(".dropzone-item");
    let id = item.dataset.id;
    let path = this.closest(".dropzone").querySelector(".dropzone-label").dataset.path;

    if (confirm("Deseja excluir a imagem?")) {
        Loading.prepend();

        fetch(`/api/${path}/${id}`, {
            method: "DELETE",
        }).then(response => {
            if (!response.ok) {
                return;
            }

            item.remove();
        }).finally(() => {
            Loading.remove();
        });
    }
};




const updateHandler = function () {
    let $list = this.closest(".dropzone").querySelectorAll(".dropzone-item");
    let path = this.closest(".dropzone").querySelector(".dropzone-label").dataset.path;

    Loading.prepend();

    let promises = Array.from($list).map($item => {
        let id = $item.dataset.id;
        let legend = $item.querySelector(".dropzone-legend").value;

        return fetch(`/api/${path}/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                legend
            })
        }).then(response => {
            if (!response.ok) {
                SweetAlert.error("Houve um erro ao salvar as alterações, tente novamente.");
                return;
            }
        });
    });

    Promise.all(promises).finally(() => {
        Loading.remove();
        this.disabled = true;
    });
};




document.addEventListener("DOMContentLoaded", () => {
    [].forEach.call(document.querySelectorAll(".dropzone-list"), el => {
        let path = el.closest(".dropzone").querySelector(".dropzone-label").dataset.path;

        Sortable.create(el, {
            onEnd: () => {
                let order = Sortable.get(el).toArray();

                fetch(`/api/${path}/reorder`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(order)
                });
            }
        });
    });

    "drag dragstart".split(" ").forEach(event => {
        on(event, ".dropzone-label", prevents);
    });

    "dragenter dragover".split(" ").forEach(event => {
        on(event, ".dropzone-label", e => {
            prevents(e);
            e.target.classList.add("is-dragover");
        });
    });

    "dragleave dragend".split(" ").forEach(event => {
        on(event, ".dropzone-label", e => {
            prevents(e);
            e.target.classList.remove("is-dragover");
        });
    });

    on("drop", ".dropzone-label", dropHandler);

    on("change", ".dropzone-input", function () {
        let $label = this.closest(".dropzone").querySelector(".dropzone-label");
        let path = $label.dataset.path;
        let relation = {
            attribute: $label.dataset.relationAttribute,
            id: $label.dataset.relationId
        };
        sendFiles(this.files, path, relation);
    });

    on("click", ".dropzone-trash", trashHandler);

    on("change", ".dropzone-legend", function () {
        let $updateButton = this.closest(".dropzone").querySelector(".dropzone-update");

        if ($updateButton.disabled) {
            $updateButton.disabled = false;
        }
    });

    on("click", ".dropzone-update", updateHandler);
});




const on = (event, selector, handler) => {
    document.addEventListener(event, function (e) {
        for (var target = e.target; target && target != this; target = target.parentNode) {
            if (target.matches(selector)) {
                handler.call(target, e);
                break;
            }
        }
    }, false);
};
