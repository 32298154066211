document.addEventListener("DOMContentLoaded", () => {
    _.on("submit", "form.email", function (e) {
        e.preventDefault();
        e.stopPropagation();
        let $form = this;
        Form.buttonLoading($form);
        Form.removeAlert($form);

        if (!Form.isValid($form)) {
            Form.buttonSend($form);
            Form.goUp($form);
            return;
        }

        let data = new FormData($form);
        let origin = $form.dataset.origin;
        data.append("origin", origin);
        let type = $form.dataset.type;
        data.append("type", type);

        if ($form.dataset.grecaptcha) {
            grecaptcha.ready(async () => {
                let clientKey = $form.dataset.grecaptcha;
                data.append("g-recaptcha-response", await grecaptchaToken(clientKey, origin));
                send($form, data, origin);
            });
        } else {
            send($form, data, origin);
        }
    });
});

const send = ($form, data, origin) => {
    fetch("api/emails", {
        method: "POST",
        body: data,
    })
        .then(response => {
            if (!response.ok) {
                Form.showError($form, "Houve um erro ao enviar, tente novamente.");
                return;
            }

            let name = $form.querySelector('input[name="name"]').value;
            let success = $form.dataset.success ? $form.dataset.success : "sua mensagem foi enviada com sucesso! Retornaremos em breve";
            Form.showSuccess($form, `${name}, ${success}.`);
        })
        .then(() => {
            $form.reset();
            Form.goUp($form);
            Form.buttonSend($form);
        });
};

const grecaptchaToken = (clientKey, origin) => {
    origin = origin.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return grecaptcha.execute(clientKey, { action: origin });
};
