$(document).ready(() => {
    $.fn.dataTable.moment("DD/MM/YYYY");
    $.fn.dataTable.moment("DD/MM/YYYY às HH:mm:ss");

    $(".table-datatables").DataTable({
        language: {
            url:
                "//cdn.datatables.net/plug-ins/1.10.15/i18n/Portuguese-Brasil.json",
            buttons: {
                pageLength: {
                    _: "%d registros por página +",
                    "-1": "Todos"
                }
            }
        },
        order: [],
        columnDefs: [
            {
                targets: "tbl_acao",
                sortable: false,
                ordable: false
            }
        ],
        lengthMenu: [
            [10, 25, 50, -1],
            ["10 registros", "25 registros", "50 registros", "Todos"]
        ],
        dom: "Bfrtip",
        buttons: ["pageLength", "copyHtml5", "excelHtml5", "pdfHtml5"],
        responsive: true
    });
});
